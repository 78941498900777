import { useMemo, useEffect } from 'react'
import {
  useGetAssemblyDeliveryPolicy,
  useGetCyberSecurityPolicyQuery,
  useGetMedicalAcceptOffer,
  useGetOffer,
  useMutateAcceptOffer,
  useMutateAssemblyDeliveryPolicyAccept,
  useMutateCyberSecurityPolicyAccept,
  useMutateMedicalOfferAccept
} from '../api/react-query/partners'
import { digestMessage, getMedicalOfferParams, renderMedicalOfferTemplate } from '../helper'
import { AppConfig } from '../AppConfig'
import { message } from 'antd'
import { offerScreen, errors } from '../translates'
import { partnerRoles4MultiOffer } from '../Constants'
import { useStores } from '../Store/Store'

const acceptType = {
  acceptOffer: 'acceptOffer',
  acceptCyber: 'acceptCyber',
  acceptAssembly: 'acceptAssembly',
  acceptMedical: 'acceptMedical'
}

function useRequiredOffers ({ role, onSuccess }) {
  const { partnerProfile } = useStores()

  const onlyRoleOffer = useMemo(() => {
    return !partnerRoles4MultiOffer.includes(role)
  }, [role])

  const { data: dataRoleOffer, isError: isErrorRoleOffer } = useGetOffer(role, {
    enabled: !!role,
    refetchOnWindowFocus: false
  })

  const {
    data: dataCyberSecurityPolicy,
    isError: isErrorCyberSecurityPolicy
  } = useGetCyberSecurityPolicyQuery({
    enabled: !onlyRoleOffer,
    refetchOnWindowFocus: false
  })

  const {
    data: dataAssemblyDeliveryPolicy,
    isError: isErrorAssemblyDeliveryPolicy
  } = useGetAssemblyDeliveryPolicy({
    enabled: !onlyRoleOffer,
    refetchOnWindowFocus: false
  })

  const {
    data: dataMedicalOffer,
    isError: isErrorMedicalOffer
  } = useGetMedicalAcceptOffer({
    enabled: !onlyRoleOffer,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (isErrorRoleOffer || isErrorCyberSecurityPolicy || isErrorAssemblyDeliveryPolicy || isErrorMedicalOffer) {
      message.error(offerScreen.errors.offer)
    }
  }, [isErrorRoleOffer, isErrorCyberSecurityPolicy, isErrorAssemblyDeliveryPolicy, isErrorMedicalOffer])

  const {
    mutate: acceptRoleOffer,
    data: dataAcceptRoleOffer,
    isLoading: isLoadingAcceptRoleOffer,
    isError: isErrorAcceptRoleOffer
  } = useMutateAcceptOffer()

  const {
    mutate: acceptCyberSecurityPolicy,
    data: dataAcceptCyberSecurityPolicy,
    isLoading: isLoadingAcceptCyberSecurityPolicy,
    isError: isErrorAcceptCyberSecurityPolicy
  } = useMutateCyberSecurityPolicyAccept()

  const {
    mutate: acceptAssemblyDeliveryPolicy,
    data: dataAcceptAssemblyDeliveryPolicy,
    isLoading: isLoadingAcceptAssemblyDeliveryPolicy,
    isError: isErrorAcceptAssemblyDeliveryPolicy
  } = useMutateAssemblyDeliveryPolicyAccept()

  const {
    mutate: acceptMedicalOffer,
    data: dataAcceptMedicalOffer,
    isLoading: isLoadingAcceptMedicalOffer,
    isError: isErrorAcceptMedicalOffer
  } = useMutateMedicalOfferAccept()

  const roleOfferData = useMemo(() => dataRoleOffer || {}, [dataRoleOffer])

  const acceptRoleOfferAsync = async function (offerData) {
    const hash = await digestMessage(offerData.offer?.subject + offerData.offer?.body + offerData.offer?.agreementTerm)
    acceptRoleOffer({
      offerId: offerData.offer?.id,
      offerHash: hash,
      validityFrom: offerData.validityFrom,
      validityTo: offerData.validityTo
    })
  }

  const acceptMedicalOfferAsync = async function (offerData, profile) {
    const content = renderMedicalOfferTemplate(offerData?.template?.content, profile)
    const hash = await digestMessage(content)
    acceptMedicalOffer({
      templateId: offerData?.template?.id,
      signHash: hash,
      signData: getMedicalOfferParams(profile)
    })
  }

  const acceptListOffers = () => {
    if (isNeedAccept(acceptType.acceptOffer, partnerProfile?.profile)) {
      acceptRoleOfferAsync(roleOfferData)
    } else if (isNeedAccept(acceptType.acceptCyber, partnerProfile?.profile) && !onlyRoleOffer) {
      acceptCyberSecurityPolicy({ agreementId: dataCyberSecurityPolicy?.agreement?.id })
    } else if (isNeedAccept(acceptType.acceptAssembly, partnerProfile?.profile) && !onlyRoleOffer) {
      acceptAssemblyDeliveryPolicy({ agreementId: dataAssemblyDeliveryPolicy?.agreement?.id })
    } else if (isNeedAccept(partnerProfile?.profile) && !onlyRoleOffer) {
      acceptMedicalOfferAsync(dataMedicalOffer, partnerProfile?.profile)
    }
    // acceptRoleOfferAsync(roleOfferData)
  }

  const acceptOnlyMedicalOffer = (profile) => {
    acceptMedicalOfferAsync(dataMedicalOffer, profile)
  }

  useEffect(() => {
    if (dataAcceptRoleOffer?.isSuccess) {
      partnerProfile?.setPartnerProfile(dataAcceptRoleOffer?.partner)
      if (onlyRoleOffer) {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      } else if (isNeedAccept(acceptType.acceptCyber, dataAcceptRoleOffer?.partner?.profile)) {
        acceptCyberSecurityPolicy({ agreementId: dataCyberSecurityPolicy?.agreement?.id })
      }
    } else if (isErrorAcceptRoleOffer || dataAcceptRoleOffer?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptRoleOffer?.errorMessage || `Ошибка подтверждения ${offerScreen.offerParts.roleOffer}`)
    }
  }, [isErrorAcceptRoleOffer, dataAcceptRoleOffer, dataCyberSecurityPolicy])

  useEffect(() => {
    if (dataAcceptCyberSecurityPolicy?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptCyberSecurityPolicy?.profile })
      if (isNeedAccept(acceptType.acceptAssembly, dataAcceptCyberSecurityPolicy?.profile)) {
        acceptAssemblyDeliveryPolicy({ agreementId: dataAssemblyDeliveryPolicy?.agreement?.id })
      }
    } else if (isErrorAcceptCyberSecurityPolicy || dataAcceptCyberSecurityPolicy?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptCyberSecurityPolicy?.errorMessage || `Ошибка подтверждения ${offerScreen.offerParts.cyberSecurityPolicy}`)
    }
  }, [isErrorAcceptCyberSecurityPolicy, dataAcceptCyberSecurityPolicy, dataAssemblyDeliveryPolicy])

  useEffect(() => {
    if (dataAcceptAssemblyDeliveryPolicy?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptAssemblyDeliveryPolicy?.profile })
      if (isNeedAccept(partnerProfile?.profile)) {
        acceptMedicalOfferAsync(dataMedicalOffer, dataAcceptAssemblyDeliveryPolicy?.profile)
      } else {
        onSuccess(AppConfig.partnerSteps.stepSuccess)
      }
    } else if (isErrorAcceptAssemblyDeliveryPolicy || dataAcceptAssemblyDeliveryPolicy?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptAssemblyDeliveryPolicy?.errorMessage || `Ошибка подтверждения ${offerScreen.offerParts.assemblyAndDeliveryPolicy}`)
    }
  }, [isErrorAcceptAssemblyDeliveryPolicy, dataAcceptAssemblyDeliveryPolicy, dataMedicalOffer, partnerProfile])

  useEffect(() => {
    if (dataAcceptMedicalOffer?.isSuccess) {
      partnerProfile?.setPartnerProfile({ profile: dataAcceptMedicalOffer?.profile })
      onSuccess(AppConfig.partnerSteps.stepSuccess)
    } else if (isErrorAcceptMedicalOffer || dataAcceptMedicalOffer?.errorMessage) {
      onSuccess(AppConfig.partnerSteps.stepOffer)
      message.error(dataAcceptMedicalOffer?.errorMessage || errors.acceptLmkAgreement)
    }
  }, [isErrorAcceptMedicalOffer, dataAcceptMedicalOffer])

  const isNeedAccept = (type, profile) => {
    if (type === acceptType.acceptOffer) {
      return !profile?.offerActive || profile?.offerExpires
    }
    if (type === acceptType.acceptCyber) {
      return !profile?.cyberSecurityPolicy?.isValid
    }
    if (type === acceptType.acceptAssembly) {
      return !profile?.assemblyAndDeliveryPolicy?.isValid
    }
    if (type === acceptType.acceptMedical) {
      return profile?.lmkDetails?.hasMedicalBook
    }
    return false
  }

  const isLoadingAccept = useMemo(() => {
    return isLoadingAcceptRoleOffer ||
      isLoadingAcceptCyberSecurityPolicy ||
      isLoadingAcceptAssemblyDeliveryPolicy ||
      isLoadingAcceptMedicalOffer
  }, [
    isLoadingAcceptRoleOffer,
    isLoadingAcceptCyberSecurityPolicy,
    isLoadingAcceptAssemblyDeliveryPolicy,
    isLoadingAcceptMedicalOffer
  ])

  return {
    acceptListOffers,
    acceptOnlyMedicalOffer,
    isLoadingAccept
  }
}

export default useRequiredOffers
